import { useEffect, useMemo, useRef, useState } from 'react'

import debounce from 'lodash/debounce'
import memoize from 'lodash/memoize'
import { useIntl } from 'react-intl'
import { styled } from 'styled-components'

import type { DropdownFilterTypes } from '@components/layouts/UserPageTemplate/DropdownFilter'
import DropdownFilter from '@components/layouts/UserPageTemplate/DropdownFilter'
import type { AnyFilterState } from '@components/SearchProvider'

import type { FilterControls } from './types'
import {
  calculateFilterAppliedText,
  calculateSelectedFilterCount,
  calculateSelectedFilterPriority,
  getShouldShowFilterApplied,
  getTextWidth,
} from './utils'

const FilterBarContainer = styled.div`
  display: flex;

  // fall back if filterbar does not auto fit
  overflow-x: auto;

  // If there is no search-box, fill the full row
  &:first-child {
    grid-column-start: 1;
    grid-column-end: 3;
  }

  // placeholder for gap property to support safari version <= 14
  & > * + * {
    margin-left: 16px;
  }
`

const FilterBar = <FiltersState extends AnyFilterState>({
  filterControls,
}: {
  filterControls: FilterControls<FiltersState>
}) => {
  const [filterBarElement, setFilterBarElement] = useState<HTMLElement | null>(
    null
  )
  const [amtToShow, setAmtToShow] = useState(5)
  const intl = useIntl()

  const filterChangeCounter = useRef(0)

  const prioritizedFilterListRef = useRef<(keyof FiltersState)[]>([])
  const setPrioritizedFilter = (filterKey?: keyof FiltersState) => {
    if (Array.isArray(prioritizedFilterListRef.current)) {
      filterChangeCounter.current++

      if (filterKey) {
        const index = prioritizedFilterListRef.current.indexOf(filterKey)

        if (index > -1) {
          prioritizedFilterListRef.current.splice(index, 1)
          prioritizedFilterListRef.current.unshift(filterKey)
        } else {
          prioritizedFilterListRef.current.unshift(filterKey)
        }
      }
    }
  }

  const sortedFilterList = filterControls.filterList.sort((a, b) => {
    const aSelectedFilterCount = calculateSelectedFilterCount(
      a.filters,
      filterControls
    )
    const bSelectedFilterCount = calculateSelectedFilterCount(
      b.filters,
      filterControls
    )
    // move filters that have been selected to the beginning
    const aSelectedFilterPriority = calculateSelectedFilterPriority(
      a.filters,
      aSelectedFilterCount,
      prioritizedFilterListRef.current
    )
    const bSelectedFilterPriority = calculateSelectedFilterPriority(
      b.filters,
      bSelectedFilterCount,
      prioritizedFilterListRef.current
    )

    return aSelectedFilterPriority - bSelectedFilterPriority
  })

  const listedFilters = sortedFilterList.slice(0, amtToShow)

  const stackedFilters = sortedFilterList
    .slice(amtToShow)
    .reduce((prevFilter: DropdownFilterTypes<FiltersState>[], filter) => {
      return [...prevFilter, ...filter.filters]
    }, [])

  const memoizedGetTextWidth = useMemo(() => memoize(getTextWidth), [])

  useEffect(() => {
    const canvasElement = document.createElement('canvas')
    const calculateBarFit = debounce(() => {
      if (filterBarElement) {
        const buttonSpacingWidth = 92
        const selectedButtonWithCountWidth = 24
        const firstAndLastOffset = 16
        const moreTextWidth =
          getTextWidth(
            intl.formatMessage({ id: 'more', defaultMessage: 'more' }),
            'bold 14px Proxima Nova',
            canvasElement
          ) + buttonSpacingWidth
        const maxWidth = filterBarElement.offsetWidth

        let currentWidth = 0
        let fittedLength = 0

        for (let i = 0; i < filterControls.filterList.length; i++) {
          const filter = filterControls.filterList[i]
          const selectedFiltersCount = calculateSelectedFilterCount(
            filter.filters,
            filterControls
          )
          const shouldShowFilterApplied = getShouldShowFilterApplied(
            filter.filters,
            selectedFiltersCount
          )
          const value =
            shouldShowFilterApplied &&
            calculateFilterAppliedText(filter.filters, filterControls, intl)

          const title = intl.formatMessage(
            shouldShowFilterApplied
              ? {
                  id: `${filter.title}.short`,
                  defaultMessage: `${filter.title}.short`,
                }
              : { id: filter.title, defaultMessage: filter.title },
            shouldShowFilterApplied ? { value } : undefined
          )

          const titleTextWidth = memoizedGetTextWidth(
            title,
            `bold 14px 'Proxima Nova'`,
            canvasElement
          )

          const buttonOffset =
            selectedFiltersCount > 1 ? selectedButtonWithCountWidth : 0
          const totalWidth =
            Math.ceil(titleTextWidth) + buttonSpacingWidth + buttonOffset

          if (
            currentWidth + totalWidth + moreTextWidth - firstAndLastOffset <
            maxWidth
          ) {
            currentWidth = currentWidth + totalWidth
            fittedLength = fittedLength + 1
          } else {
            break
          }
        }

        setAmtToShow(fittedLength)
      }
    }, 150)

    if (filterBarElement) {
      // initialize resize bar on page load
      calculateBarFit()
    }

    window.addEventListener('resize', calculateBarFit)

    return () => {
      calculateBarFit.cancel()
      window.removeEventListener('resize', calculateBarFit)
    }
  }, [filterBarElement, filterChangeCounter.current])

  return (
    <FilterBarContainer ref={setFilterBarElement}>
      {listedFilters.map((filter, index) => (
        <DropdownFilter<FiltersState>
          key={`${filter.title}-${index}`}
          buttonLabel={filter.title}
          filters={filter.filters}
          filterControls={filterControls}
          maxHeight="40vh"
          setPrioritizedFilter={setPrioritizedFilter}
        />
      ))}
      {stackedFilters.length ? (
        <DropdownFilter<FiltersState>
          buttonLabel="more"
          filters={stackedFilters}
          filterControls={filterControls}
          maxHeight="40vh"
          setPrioritizedFilter={setPrioritizedFilter}
        />
      ) : null}
    </FilterBarContainer>
  )
}

export { FilterBarContainer }
export default FilterBar
