import { useMemo } from 'react'

import {
  parseISO,
  formatISO,
  subDays,
  startOfDay,
  endOfDay,
  subYears,
  getYear,
  startOfYear,
  endOfYear,
} from 'date-fns'
import { styled } from 'styled-components'

import { Calendar, Radio, FormattedMessage } from '@b-stock/bstock-react'
import { designColors } from '@b-stock/bstock-react/theme'

import type { Range } from '@commonTypes'
import type { AnyFilterState } from '@components/SearchProvider'

import type { UpdateFilter } from '../..'
import { Container, Title } from '../MultiChecklist/MultiChecklist'

type DateRangeProps<FiltersState> = {
  title?: string
  range: Range
  filterKey: keyof FiltersState
  filterState: FiltersState
  updateFilter: UpdateFilter<FiltersState>
}

const RadioContainer = styled.div`
  border-bottom: 1px solid ${designColors.neutral.lightGray};
  margin-bottom: 1.625rem;
  padding-bottom: 0.8125rem;
`

const NUM_OF_YEARS_BACK = 3

const createLastYears = (maxYears: number): DateRadio[] => {
  const baseTime = new Date()

  return new Array(maxYears).fill(null).map((_, i) => {
    const year = subYears(baseTime, i)
    return {
      label: getYear(year).toString(),
      value: [formatISO(startOfYear(year)), formatISO(endOfYear(year))],
    }
  })
}

type DateRangeType = [null | string, null | string]

type DateRadio = {
  label: string
  value: DateRangeType
}

type DateFilterRadioMap = {
  lastDays: DateRadio[]
  lastYears: DateRadio[]
}

const now = new Date()
const dateFilterRadios: DateFilterRadioMap = {
  lastDays: [
    {
      label: 'days.7',
      value: [formatISO(startOfDay(subDays(now, 7))), formatISO(endOfDay(now))],
    },
    {
      label: 'days.15',
      value: [
        formatISO(startOfDay(subDays(now, 15))),
        formatISO(endOfDay(now)),
      ],
    },
    {
      label: 'days.30',
      value: [
        formatISO(startOfDay(subDays(now, 30))),
        formatISO(endOfDay(now)),
      ],
    },
    {
      label: 'days.90',
      value: [
        formatISO(startOfDay(subDays(now, 90))),
        formatISO(endOfDay(now)),
      ],
    },
  ],
  lastYears: createLastYears(NUM_OF_YEARS_BACK),
}

const checkMatchingDates = (value: DateRangeType, range: DateRangeType) => {
  return value[0] === range[0] && value[1] === range[1]
}

/*
export const CalendarSingleDatePicker = () => {
  const [selected, setSelected] = useState<Date | undefined>(pastDate)

  let footer = <p>Please pick a day.</p>
  if (selected) {
    footer = <p>You picked {format(selected, 'PP')}.</p>
  }
  return (
    <>
      <Calendar
        mode="single"
        defaultMonth={pastDate}
        selected={selected}
        showOutsideDays={false}
        onSelect={setSelected}
      />
      {footer}
    </>
  )
}
*/

const DateRange = <FiltersState extends AnyFilterState>({
  title,
  filterKey,
  filterState,
  updateFilter,
}: DateRangeProps<FiltersState>) => {
  const range = filterState[filterKey] as DateRangeType
  const defaultMonth = useMemo(() => new Date(), [])

  const setRange = (value: DateRangeType) => {
    updateFilter(filterKey, value)
  }

  const handleRadioOnChange = (value: DateRangeType) => {
    setRange(value) //TODO: fix bug with year range & calendar
  }

  return (
    <Container>
      {title && (
        <Title>
          <FormattedMessage id={title} defaultMessage={title} />
        </Title>
      )}

      <RadioContainer role="radiogroup" aria-label="Last XX Days">
        {dateFilterRadios.lastDays.map((item) => {
          return (
            <Radio
              key={item.label}
              label={
                <FormattedMessage
                  id={`Filters.${item.label}`}
                  defaultMessage={`Filters.${item.label}`}
                />
              }
              onChange={() => handleRadioOnChange(item.value)}
              checked={checkMatchingDates(item.value, range)}
            />
          )
        })}
      </RadioContainer>

      <RadioContainer role="radiogroup" aria-label="Selected Year">
        {dateFilterRadios.lastYears.map((item) => {
          return (
            <Radio
              key={item.label}
              label={item.label}
              onChange={() => handleRadioOnChange(item.value)}
              checked={checkMatchingDates(item.value, range)}
            />
          )
        })}
      </RadioContainer>

      <Calendar
        mode="range"
        defaultMonth={defaultMonth}
        onSelect={(range) => {
          if (range?.from && range?.to) {
            setRange([formatISO(range.from), formatISO(range.to)])
          }
        }}
        selected={
          range[0] && range[1]
            ? { from: parseISO(range[0]), to: parseISO(range[1]) }
            : undefined
        }
      />
    </Container>
  )
}

export type { DateRangeType }
export { dateFilterRadios }
export default DateRange
