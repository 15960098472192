const isPlainObject = (o: unknown) => typeof o === 'object' && o !== null

const stableStringify = (value: unknown): string => {
  return JSON.stringify(value, (_, val) =>
    isPlainObject(val)
      ? Object.keys(val)
          .sort()
          .reduce<Record<string, unknown>>((result, key) => {
            result[key] = val[key]
            return result
          }, {})
      : val
  )
}

export default stableStringify
